@import "src/assets/styles/media";

$headerOffset: calc(40px + 2rem);

.inventories {
  &__wrapper {
    width: 100%;
    background: var(--theme-background);
    box-shadow: 4px 4px 30px 0 rgba(75, 75, 75, 0.15);
    border: thin solid var(--theme-secondary);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }

  &__table {
    width: 100%;
    text-align: center;
    table-layout:fixed;

    &.inner {
      min-height: 2.5rem;
      tr {
        border: none !important;
      }
      td {
        min-height: 32px;
      }
      td, th {
        width: 12%;

        padding-inline: 0.5rem;
        @include mobile-420 {
          padding-inline: 8px;
        }
        @include tablet-768 {
          padding-inline: 1rem;
        }
        @include desktop-1200 {
          padding-inline: 1.5rem;
        }

        &.cell-price, &.cell-qty {
          padding: 0;
        }
        &.cell-qty {
          width: 60px;
        }
        &.cell-price {
          width: 120px;
        }
        &.cell-public-notes {
          >div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          font-size: 14px;
          width: 150px;
          @include tablet-992 {
            width: 200px;
          }
          @include desktop-1200 {
            width: 250px;
          }
        }
      }
      &.tablet {
        td, th {
          width: auto;
          &.cell-qty-price,
          &.cell-section-row {
            >div>div {
              display: flex;
              flex-wrap: nowrap;
            }
          }
          &.cell-id {
            width: 120px;
          }
          &.cell-ticket-type {
            width: 95px;
          }
          &.cell-public-notes {
            min-width: 200px;
          }
        }
      }
    }
  }

  &__thead {
    height: 2.5rem;
    background-color: var(--theme-color);
    color: var(--theme-background);
    th {
      font-size: var(--small-font-size);
    }
    position: sticky;
    top: calc(#{$headerOffset} + 3rem);
    z-index: 1;
    @include mobile-576 {
      top: $headerOffset;
    }
  }

  &__tr {
    border-bottom: thin solid var(--theme-secondary);

    &-event {
      position: sticky;
      top: calc(#{$headerOffset} + 40px + 3rem);
      @include mobile-576 {
        top: calc(#{$headerOffset} + 40px);
      }
      height: 2.5rem;
      background-color: var(--theme-secondary);
      text-align: left;
      font-size: var(--small-font-size);
      font-weight: var(--font-bold);

      td:not(:first-child) {
        padding-inline: 1rem;
        @include tablet-992 {
          padding-inline: 1.5rem;
        }
        @include desktop-1200 {
          padding-inline: 2rem;
        }
      }

      td:first-child {
        text-align: center;
      }
    }

    &-inventory {
      border-bottom: thin solid var(--theme-secondary);
      height: 3.125rem;
      @include tablet-992 {
        height: auto;
      }
      td {
        min-height: 32px;
        >table:not(.tablet-cell) {
          min-height: 32px;
          tr {
            border: none !important;
            td:nth-child(2n+1) {
              background-color: var(--theme-primary);
            }
          }
        }
      }

      .cell-check, .cell-actions {
        width: 5%;
      }

      &-empty {
        height: 3.125rem;
      }

      .notes-wrapper {
        flex-wrap: wrap;
        @include tablet-768 {
          flex-wrap: nowrap;
        }
      }
    }

    &-head th,
    &-inventory td {
      .mobile-view {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
        text-align: left;
        >div {
          display: flex;
          gap: 0.25rem;
          justify-content: flex-start;
        }
        .cell-public-notes-mobile {
          >div {
            display: none;
          }
          &.expanded {
            .cell-public-notes-mobile-full {
              display: block;
            }
          }
          &:not(.expanded) {
            .cell-public-notes-mobile-short {
              display: flex;
              gap: 10px;
            }
          }
          .toggle {
            text-align: right;
            display: block;
            text-decoration: underline;
          }
        }
        .cell-public-notes {
          flex-basis: 50%;
          flex-grow: 1;
          overflow: hidden;
          width: 100%;
          >div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
      &.cell-actions, &.cell-check, &.cell-price, &.cell-qty {
        padding: 0;
      }
      &.cell-check {
        width: 40px;
      }
      &.cell-actions {
        width: 50px;
      }
      .mobile-view, .tablet {
        label {
          font-weight: bold;
        }
      }
      .tablet {
        label {
          margin-right: 0.25rem;
        }
      }
    }

    &-inventory td {
      font-size: 14px;
      @include desktop-1200 {
        font-size: 16px;
      }
    }
  }
}
.tablet-cell {
  width: 100%;
  padding: 0.5rem 0;
  >tbody >tr >td {
    text-align: right;
    background: none !important;
    width: auto !important;
    padding: 0 !important;
    label {
      width: 100%;
      text-align: left;
    }
  }
}
