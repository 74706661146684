@import "src/assets/styles/media";

.hold {
  padding: 1rem;

  &__info {
    &__event {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: var(--theme-secondary);

      &_name {
        font-weight: var(--font-bold);
      }
    }

    &__inventory {
      display: flex;
      justify-content: space-between;

      &_title {
        font-weight: var(--font-bold);
      }

      &_value {
        text-align: center;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    &_title {
      font-weight: var(--font-bold);
    }

    &_wrapper {
      display: flex;
      flex-direction: column;
      gap: .5rem;
    }
  }
}

@include mobile-420 {
  .hold {
    &__form {
      &_wrapper {
        flex-direction: row;
      }

      input {
        width: 100%;
      }
    }
  }
}
