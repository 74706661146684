@import "src/assets/styles/variables";

@mixin desktop-1200 {
  @media (min-width: $desktop-1200 - 1) {
    @content;
  }
}

@mixin tablet-992 {
  @media (min-width: $tablet-992 - 1) {
    @content;
  }
}

@mixin tablet-768 {
  @media (min-width: $tablet-768 - 1) {
    @content;
  }
}

@mixin mobile-576 {
  @media (min-width: $mobile-576 - 1) {
    @content;
  }
}

@mixin mobile-420 {
  @media (min-width: $mobile-420 - 1) {
    @content;
  }
}
