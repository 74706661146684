.quote {
  height: 30vh;
  padding: .5rem;
  overflow-y: auto;
  border-bottom: thin solid var(--theme-secondary);

  &__event_li {
    padding-bottom: .5rem;
    font-weight: var(--font-bold);
  }

  &__inventory {
    &_li {
      display: flex;
      width: 100%;
      flex-direction: row;
    }

    &_label {
      display: flex;
      height: 2.5rem;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-weight: var(--font-normal);
      border-radius: 0.3125rem;
      border: thin solid var(--theme-secondary);
      padding-inline: .5rem;
      margin-bottom: .25rem;
      cursor: pointer;
    }

    &_input {
      display: none;
    }

    &_input:checked + &_label {
      background-color: var(--theme-primary);
    }

    &_input[type="radio"]:checked + .button {
      background-color: var(--theme-color);
      color: var(--theme-background);
    }

    &_textarea {
      width: 100%;
      height: 6rem;
    }

    &_switch-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }

  &__fields {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: .5rem;
    margin-bottom: 1rem;
  }

  &__field {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &_title {
      font-weight: var(--font-bold);
    }

    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1rem;
    }
  }
}

.arrow-up {
  transform: rotate(180deg);
}

.old-price {
  color: var(--theme-subtitle-color);
}

@media (max-width: 991px) {
  .quote {
    height: 25vh;

    &__fields {
      flex-wrap: wrap;
    }
  }
}
