.modal {
  &-title {
    font-weight: var(--font-bold);
    font-size: var(--large-font-size);
  }

  &-content {
    background-color: var(--theme-background);
    color: var(--theme-color);
  }

  &-body {
    padding: 0;
  }

  &-submit {
    align-self: center;
    margin-top: 1rem;
    width: 100%;
  }
}

.btn-close {
  color: var(--theme-color);
  background-color: white;
  opacity: 1;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}