$--theme-primary: --theme-primary;
$--theme-secondary: --theme-secondary;
$--theme-background: --theme-background;
$--theme-color: --theme-color;
$--theme-subtitle-color: --theme-subtitle-color;

$theme-map-white: (
        $--theme-primary: #ECECEC,
        $--theme-secondary: #DFDFDF,
        $--theme-background: #FFFFFF,
        $--theme-color: #333333,
        $--theme-subtitle-color: #ACACAC,
);

$theme-map-black: (
        $--theme-primary: #292929,
        $--theme-secondary: #3C3C3C,
        $--theme-background: #232323,
        $--theme-color: #FFFFFF,
        $--theme-subtitle-color: #8C8C8C,
);

$theme-map-blue: (
        $--theme-primary: #EDEEF6,
        $--theme-secondary: #DBDDF0,
        $--theme-background: #FFFFFF,
        $--theme-color: #3A4076,
        $--theme-subtitle-color: #ACACAC,
);

$theme-map-red: (
        $--theme-primary: #F4F1F1,
        $--theme-secondary: #EAD5D0,
        $--theme-background: #FFFFFF,
        $--theme-color: #373331,
        $--theme-subtitle-color: #ACACAC,
);

$theme-map-green: (
        $--theme-primary: #ECF2F0,
        $--theme-secondary: #DCEADC,
        $--theme-background: #FFFFFF,
        $--theme-color: #313732,
        $--theme-subtitle-color: #ACACAC,
);

:root {
  --body-font: "Century Gothic", sans-serif;
  --larger-font-size: 2rem;
  --large-font-size: 1.5rem;
  --normal-font-size: 1rem;
  --small-font-size: .875rem;

  --font-normal: 400;
  --font-bold: 700;
}

$mobile-420: 420px; // var(--bs-breakpoint-xs);
$mobile-576: 576px; // var(--bs-breakpoint-sm)
$tablet-768: 768px; // var(--bs-breakpoint-md)
$tablet-992: 992px; // var(--bs-breakpoint-lg)
$desktop-1200: 1200px; // var(--bs-breakpoint-xl)
