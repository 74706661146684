@import 'variables';
@import 'mixins';

:root.white {
  @include spread-map($theme-map-white);
}

:root.black {
  @include spread-map($theme-map-black);
}

:root.blue {
  @include spread-map($theme-map-blue);
}

:root.red {
  @include spread-map($theme-map-red);
}

:root.green {
  @include spread-map($theme-map-green);
}