
.success-response {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid var(--theme-secondary);

  &__event-container {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    h6 {
      margin: 0;
    }
  }

  &__button {
    margin: 1.5rem auto 0;
    width: 50%;
  }
}
