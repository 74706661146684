@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;700&display=swap');
@import '~bootstrap/scss/bootstrap';
@import "src/assets/styles/themes";
@import "src/assets/styles/media";

*, ul, p {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: var(--font-normal);
  background-color: var(--theme-background);
  color: var(--theme-color);
  overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

h1 {
  color: var(--theme-color);
  font-weight: var(--font-bold);
  font-size: var(--larger-font-size);
  font-family: var(--body-font);
}

.dropdown {
  position: inherit;

  &-toogle {
    position: inherit;
    z-index: 0;
    border: thin solid var(--theme-secondary) !important;
    background-color: var(--theme-background) !important;
    border-radius: 0.3125rem;
    font-weight: var(--font-bold);
    font-size: var(--small-font-size);
    padding: 0;

    &:focus, &:active, &:hover, &:focus-visible {
      border: thin solid var(--theme-background) !important;
      background-color: var(--theme-background) !important;
      outline: none;
      box-shadow: none;
    }

    &::after {
      content: none;
    }
  }

  &-menu {
    background-color: var(--theme-background);
    color: var(--theme-color);
  }

  &-item {
    color: var(--theme-color);

    &:active {
      background-color: var(--theme-background);
      color: var(--theme-color);
    }
  }
}

.button {
  height: 2.5rem;
  border: thin solid var(--theme-secondary);
  background-color: var(--theme-background);
  color: var(--theme-color);
  border-radius: 0.3125rem;
  font-weight: var(--font-bold);
  font-size: var(--small-font-size);
  padding-inline: 0.75rem;

  &:disabled,
  &[disabled] {
    border: thin solid var(--theme-secondary);
    background-color: var(--theme-primary);
  }

  &:focus {
    border: thin solid var(--theme-color);
  }

  span {
    padding-right: .5rem;
    display: block;
    width: 24px;
    height: 24px;
  }

  &_submit {
    background-color: var(--theme-color);
    color: var(--theme-background);
  }
}

.input, input[name="phone"] {
  height: 2.5rem;
  padding-inline: 0.75rem;
  border-radius: 0.3125rem;
  border: thin solid var(--theme-secondary);
  background-color: var(--theme-background);
  color: var(--theme-color);
  width: 100%;

  &:focus {
    outline: none;
  }
}

.input, input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input, input[type=number] {
  -moz-appearance: textfield;
}

.select {
  position: relative;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}

.content-container {
  @include desktop-1200 {
    max-width: 1230px;
  }
  width: 100%;
  margin: 0 auto;
}

.cols {
  gap: 0.5rem 0;
  margin: 0 -0.5rem;
  >div {
    padding: 0 0.5rem;
  }
}

.react-datepicker-popper {
  .react-datepicker {
    display: flex;
    @include mobile-420 {
      display: inline-block;
    }
  }
}

