@import "src/assets/styles/media";

.header {
  position: relative;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, var(--theme-primary) 50%, transparent 50%);
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem 1rem 0.75rem;

  &__user {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: var(--font-bold);

    &-logo {
      margin-right: 0.5rem;

      img {
        width: 3rem;
        height: 3rem;
        margin-bottom: 0;
      }
    }

    &__data {
      display: flex;
      flex-direction: column;
    }

    &-name {
      font-size: var(--large-font-size);
    }

    &-company {
      color: var(--theme-subtitle-color);
      font-size: var(--normal-font-size);
    }

  }

  &__social {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    &-mail, &-telegram, &-whatsapp {
      position: relative;
      >img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &__tooltip-mail, &__tooltip-telegram, &__tooltip-whatsapp {
      position: absolute;
      width: 10rem;
      z-index: 1;
      top: 110%;
      left: -150%;
      visibility: hidden;
      background-color: var(--theme-background);
      border: thin solid var(--theme-secondary);
      text-align: center;
      border-radius: 0.5rem;
    }

    &-mail:hover &__tooltip-mail {
      visibility: visible;
    }

    &-telegram:hover &__tooltip-telegram {
      visibility: visible;
    }

    &-whatsapp:hover &__tooltip-whatsapp {
      visibility: visible;
    }
  }
}

@include mobile-420 {
  .nav {
    margin: 0 auto;
    &__user-logo {
      margin-right: 0.5rem;
    }
  }
}

@include mobile-576 {
  .nav {
    padding: 2rem 1rem 0.75rem;
    &__user-logo {
      margin-right: 1rem;
      img {
        width: 4rem;
        height: 4rem;
      }
    }
    &__social {
      &-mail, &-telegram, &-whatsapp {
        >img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}

@include desktop-1200 {
  .nav {
    &__user-logo {
      margin-right: 1.5rem;
      img {
        width: 7.5rem;
        height: 7.5rem;
        margin-bottom: 1rem;
      }
    }
    &__social {
      margin-bottom: 0.5rem;
      &-mail, &-telegram, &-whatsapp {
        >img {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
  }
}

