@import "src/assets/styles/media";

.filters {
  position: sticky;
  top: 0;
  background-color: var(--theme-primary);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  z-index: 2;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;

    & >* {
      flex-basis: 50%;
      flex-grow: 1;
    }

    &-buttons {
      display: flex;
      gap: 8px;
      flex-direction: column;
      @include mobile-576 {
        flex-direction: row;
      }
    }

    .react-datepicker__tab-loop {
      margin: 0;
    }

    &-primary {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;
      @include desktop-1200 {
        gap: 4rem;
      }
      @include tablet-992 {
        gap: 3rem;
      }
    }

    &-secondary {
      display: flex;
      flex-direction: column;
    }
  }
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  > * {
    box-sizing: border-box;
  }

  .react-datepicker__tab-loop {
    margin: 0 !important;
  }

  input {
    width: 100%;
  }
}

.react-datepicker {
  &-wrapper {
    width: 100%;
    input {
      width: 100%;
      height: 2.5rem;
      padding-inline: 0.75rem;
      border-radius: 0.3125rem;
      border: thin solid var(--theme-secondary);
      background-color: var(--theme-background);
      color: var(--theme-color);

      &:focus {
        outline: none;
      }
    }
  }
}

@include mobile-576 {
  .filters {
    &__wrapper {
      flex-direction: row;
      &-primary {
        .input {
          width: 100%;
        }
      }
    }
  }

  .filter-container {
    flex-direction: row;
    gap: 0.5rem;
  }
}
